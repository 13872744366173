import { LeadSource } from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { BadgeColors, BadgeColorsProps } from '../components'

export const leadSourceLabel: Record<string, string> = {
  [LeadSource.acqDirect]: 'ACQ Direct',
  [LeadSource.airBnb]: 'Airbnb',
  AirBnB: 'Airbnb',
  [LeadSource.allegiantAir]: 'Allegiant Air',
  [LeadSource.beachGirls]: 'Beach Girls',
  [LeadSource.beachside]: 'Beachside',
  [LeadSource.blueGround]: 'Blueground',
  [LeadSource.bnbFinder]: 'Bnbfinder',
  [LeadSource.bonotel]: 'Bonotel',
  [LeadSource.bookingCom]: 'Booking.com',
  [LeadSource.capitalOne]: 'Capital One',
  [LeadSource.expedia]: 'Expedia',
  [LeadSource.frontdesk]: 'FrontDesk',
  [LeadSource.gilmore]: 'The Gilmore',
  [LeadSource.google]: 'Google',
  [LeadSource.googleTravel]: 'Google Travel',
  [LeadSource.homeAway]: 'VRBO',
  [LeadSource.homesAndVillas]: 'Homes & Villas',
  [LeadSource.homeToGo]: 'HomeToGo',
  [LeadSource.hopper]: 'Hopper',
  [LeadSource.lakecations]: 'Lakecations',
  [LeadSource.lighthouse]: 'Lighthouse',
  [LeadSource.marriott]: 'Marriott',
  [LeadSource.mobile]: 'AvantStay App',
  [LeadSource.monkeyTree]: 'Monkey Tree',
  [LeadSource.nonPayingGuest]: 'VIP',
  [LeadSource.other]: 'Other',
  [LeadSource.quintess]: 'Quintess',
  [LeadSource.rentalsUnited]: 'Rentals United',
  [LeadSource.stayHvn]: 'Stay HVN',
  [LeadSource.staySense]: 'StaySense',
  [LeadSource.theWesley]: 'The Wesley',
  [LeadSource.topVillas]: 'Top Villas',
  [LeadSource.travelStaytion]: 'TravelStaytion',
  [LeadSource.tripAdvisor]: 'Trip Advisor',
  [LeadSource.vacationRenter]: 'VacationRenter',
  [LeadSource.voyage]: 'Voyage',
  [LeadSource.vrbo]: 'VRBO',
  [LeadSource.website]: 'Avantstay.com',
  [LeadSource.whimstay]: 'Whimstay',
}

export const badgeColorByLeadSource: Record<string, BadgeColorsProps> = {
  [LeadSource.acqDirect]: BadgeColors.channelAcquisitionWebsites,
  [LeadSource.airBnb]: BadgeColors.channelAirbnb,
  [LeadSource.allegiantAir]: BadgeColors.channelAllegiantAir,
  [LeadSource.beachGirls]: BadgeColors.channelAcquisitionWebsites,
  [LeadSource.beachside]: BadgeColors.channelAcquisitionWebsites,
  [LeadSource.blueGround]: BadgeColors.channelBlueGround,
  [LeadSource.bnbFinder]: BadgeColors.channelBnbFinder,
  [LeadSource.bonotel]: BadgeColors.channelBonotel,
  [LeadSource.bookingCom]: BadgeColors.channelBookingCom,
  [LeadSource.capitalOne]: BadgeColors.channelWebsite,
  [LeadSource.expedia]: BadgeColors.channelExpedia,
  [LeadSource.frontdesk]: BadgeColors.channelFrontdesk,
  [LeadSource.gilmore]: BadgeColors.channelAcquisitionWebsites,
  [LeadSource.google]: BadgeColors.channelGoogle,
  [LeadSource.googleTravel]: BadgeColors.channelGoogleTravel,
  [LeadSource.homeAway]: BadgeColors.channelVRBO,
  [LeadSource.homesAndVillas]: BadgeColors.channelHomesAndVillas,
  [LeadSource.homeToGo]: BadgeColors.channelHomeToGo,
  [LeadSource.hopper]: BadgeColors.channelHopper,
  [LeadSource.lakecations]: BadgeColors.channelAcquisitionWebsites,
  [LeadSource.lighthouse]: BadgeColors.channelLighthouse,
  [LeadSource.marriott]: BadgeColors.channelMarriott,
  [LeadSource.mobile]: BadgeColors.channelMobile,
  [LeadSource.monkeyTree]: BadgeColors.channelOther,
  [LeadSource.nonPayingGuest]: BadgeColors.channelNonPayingGuest,
  [LeadSource.other]: BadgeColors.channelOther,
  [LeadSource.quintess]: BadgeColors.channelQuintess,
  [LeadSource.rentalsUnited]: BadgeColors.channelRentalsUnited,
  [LeadSource.stayHvn]: BadgeColors.channelStayHvn,
  [LeadSource.staySense]: BadgeColors.warning,
  [LeadSource.theWesley]: BadgeColors.channelTheWesley,
  [LeadSource.topVillas]: BadgeColors.channelTheWesley,
  [LeadSource.travelStaytion]: BadgeColors.channelTravelStaytion,
  [LeadSource.tripAdvisor]: BadgeColors.channelTripAdvisor,
  [LeadSource.vacationRenter]: BadgeColors.channelVacationRenter,
  [LeadSource.voyage]: BadgeColors.channelVoyage,
  [LeadSource.vrbo]: BadgeColors.channelVRBO,
  [LeadSource.website]: BadgeColors.channelWebsite,
  [LeadSource.whimstay]: BadgeColors.channelOther,
}

export const labelByChannelSource = {
  Airbnb: 'Airbnb',
  HomeAway: 'HomeAway',
  Vrbo: 'VRBO',
  'Booking Com': 'Booking.com',
  Direct: 'Direct',
}

export const directLeadSources = [
  LeadSource.acqDirect,
  LeadSource.beachGirls,
  LeadSource.beachside,
  LeadSource.gilmore,
  LeadSource.lakecations,
  LeadSource.lighthouse,
  LeadSource.mobile,
  LeadSource.monkeyTree,
  LeadSource.theWesley,
  LeadSource.voyage,
  LeadSource.website,
]
