import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

import { IconChevronDown, IconClear } from '@avantstay/backoffice-vectors'

import { useDidMount, useOnClickOutside } from '../../hooks'
import { FlexContainer } from '../FlexContainer'
import { InfoTooltip } from '../InfoTooltip'
import { MultiValue } from './components/MultiValue'
import { OptionsList } from './components/OptionsList'
import { SingleValue } from './components/SingleValue'
import { withOptionsLoading } from './HOCs/withOptionsLoading'
import { withSizeProp } from './HOCs/withSizeProp'
import * as S from './SelectField.styles'
import { DROPDOWN_MIN_WIDTH } from './SelectField.styles'
import {
  MultiSelectFieldStyledProps,
  OptionProps,
  SelectFieldProps,
  Size,
} from './SelectField.types'
import { isMultiValue } from './SelectField.utils'

function SelectField<OptionValueType>(props: SelectFieldProps<OptionValueType>) {
  const [isFocused, setIsFocused] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const searchInputRef = useRef<HTMLInputElement | null>(null)

  const {
    className,
    dropdownIcon,
    menuPlacement = 'bottom',
    isSearchable = true,
    isDisabled,
    isLoading,
    isMulti,
    loadingMessage,
    error,
    warning,
    value: propValue,
    menuPosition,
    options: propsOptions,
    onChange,
    onClear,
    onInputChange,
    onMenuOpen,
    removeBorder,
    removeBackground,
    filterOption,
    inputValue: inputValueProp,
    size = Size.Default,
    skipOptionsFiltering,
    shouldShowTooltip = false,
    noOptionsMessage,
    defaultValue,
    placeholder,
    title,
    hideValuesFromInput,
    closeMenuAfterSelect = true,
    cleanSearchAfterSelect = false,
    isClearable = false,
    shouldForceMenuOpen = false,
  } = props
  const didMount = useDidMount()
  const [inputValue, setInputValue] = useState<string>(inputValueProp || '')
  const [value, setValue] = useState(defaultValue || propValue)

  // we keep this state internally as related props are optional
  useEffect(() => {
    setInputValue(inputValueProp || '')
  }, [inputValueProp])

  // we keep this state internally as related props are optional
  useEffect(() => {
    if (didMount) {
      // we skip initial call of this effect to not clear defaultValue when value prop is not used
      setValue(propValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propValue])

  const changeInputValue = (newValue: string) => {
    setInputValue(newValue)
    if (onInputChange) {
      onInputChange(newValue)
    }
  }

  const handleClearInput = () => {
    changeInputValue('')
  }

  useOnClickOutside(containerRef, () => {
    if (inputValue) {
      handleClearInput()
    }

    if (isFocused) {
      setIsFocused(false)
    }
  })

  const handleOptionClick = (option: OptionProps<OptionValueType>) => {
    if (onChange) {
      if (isMulti) {
        onChange([
          ...((value as MultiSelectFieldStyledProps<OptionValueType>['value']) || []),
          option,
        ])
      } else {
        onChange(option)
      }
    }
    setValue(option)
    if (closeMenuAfterSelect) {
      setIsFocused(false)
      changeInputValue('')
    } else if (cleanSearchAfterSelect) {
      changeInputValue('')
      searchInputRef?.current && searchInputRef.current.focus()
    }
  }

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value
    changeInputValue(newValue)
  }

  const handleClearSelectedOption = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isClearable) {
      e.stopPropagation()
      e.preventDefault()
      onClear?.()
    }
  }
  const getLoadingMessage = () =>
    typeof loadingMessage === 'function'
      ? loadingMessage({ inputValue })
      : (loadingMessage ?? 'Loading...')

  const handleDeselect = (option: OptionProps<OptionValueType>) => {
    if (onChange) {
      if (isMulti) {
        onChange(value ? (value as typeof propValue)!.filter(o => o !== option) : [])
      } else {
        onChange(option)
      }
    }
  }

  const shouldShowValues = () => {
    if (hideValuesFromInput) return !hideValuesFromInput
    if (!value) return false
    if (isMulti) return true
    return !(isFocused && isSearchable)
  }

  const renderValues = () =>
    isMultiValue(value, isMulti) ? (
      value?.map(v => (
        <MultiValue key={String(v.value)} option={v} handleDeselect={handleDeselect} />
      ))
    ) : (
      <SingleValue value={value} size={size} />
    )

  const renderInput = () => {
    const placeholderText =
      placeholder ??
      (isMultiValue(value, isMulti) && value && value.length === 0 ? 'Select...' : undefined)

    if (isSearchable && isFocused) {
      return (
        <S.SearchInput
          ref={searchInputRef}
          autoFocus
          placeholder={placeholderText || (!isMulti ? 'Search' : undefined)}
          onChange={handleInputChange}
          value={inputValue}
          width={(containerRef.current?.getBoundingClientRect().width ?? 0) - DROPDOWN_MIN_WIDTH}
        />
      )
    }

    const hasValue = isMulti ? Array.isArray(value) && value.length > 0 : value !== undefined

    if ((!hasValue && placeholderText) || (hasValue && hideValuesFromInput)) {
      return <S.Placeholder>{placeholderText}</S.Placeholder>
    }

    return null
  }

  const MenuWrapper = ({ children }: PropsWithChildren<unknown>) => {
    if (menuPosition === 'fixed') {
      return (
        <S.FixedWrapper
          // Element with position:fixed cannot inherit width from parent, so we need to calculate this width using JavaScript
          width={containerRef.current?.getBoundingClientRect().width}
        >
          {children}
        </S.FixedWrapper>
      )
    }
    return children
  }

  const handleClickInput = !isDisabled
    ? () => {
        if (!isFocused) {
          onMenuOpen?.()
        }

        setIsFocused(open => !open)
      }
    : undefined

  const displayClearOptionButton = isClearable && !inputValue && !isFocused && value
  return (
    <S.SelectContainer ref={containerRef} className={className} $fullLength={!!title}>
      <S.ControlWrapper
        removeBorder={removeBorder}
        removeBackground={removeBackground}
        error={error}
        warning={warning}
        isFocused={isFocused}
        isMulti={isMulti}
        isDisabled={isDisabled}
        onClick={handleClickInput}
      >
        <S.Control isDisabled={isDisabled}>
          <S.ValueContainer isMulti={isMulti} size={size}>
            {title ? <S.FieldTitle $hasValue={shouldShowValues()}>{title}</S.FieldTitle> : null}
            {shouldShowValues() ? renderValues() : null}
            {isSearchable ? renderInput() : null}
          </S.ValueContainer>
          <S.IndicatorContainer>
            {error || warning ? (
              <S.SelectIssueContainer>
                {(error || warning) && <InfoTooltip error={error} warning={warning} />}
              </S.SelectIssueContainer>
            ) : (
              <FlexContainer>
                {displayClearOptionButton ? (
                  <S.ClearButton onClick={handleClearSelectedOption}>
                    <IconClear />
                  </S.ClearButton>
                ) : null}
                <S.DropdownIndicator type="button" isDropdownOpen={isFocused}>
                  {inputValue ? (
                    <S.StyledIconCloseCircle onClick={handleClearInput} />
                  ) : (
                    (dropdownIcon ?? <IconChevronDown />)
                  )}
                </S.DropdownIndicator>
              </FlexContainer>
            )}
          </S.IndicatorContainer>
        </S.Control>
      </S.ControlWrapper>
      {isFocused || shouldForceMenuOpen ? (
        <MenuWrapper>
          <S.Menu menuPlacement={menuPlacement}>
            <S.MenuList>
              {isLoading ? (
                <S.InfoWrapper>{getLoadingMessage()}</S.InfoWrapper>
              ) : (
                <OptionsList
                  filterOption={filterOption}
                  inputValue={inputValue}
                  isMulti={isMulti}
                  noOptionsMessage={noOptionsMessage}
                  onOptionClick={handleOptionClick}
                  options={propsOptions}
                  size={size}
                  shouldShowTooltip={shouldShowTooltip}
                  skipOptionsFiltering={skipOptionsFiltering}
                  value={value}
                />
              )}
            </S.MenuList>
          </S.Menu>
        </MenuWrapper>
      ) : null}
    </S.SelectContainer>
  )
}

SelectField.Default = SelectField
SelectField.Big = withSizeProp(SelectField, Size.Big)
SelectField.Small = withSizeProp(SelectField, Size.Small)

SelectField.Async = withOptionsLoading(SelectField)

export { SelectField }
