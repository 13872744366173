import arriereBackoffice from '@avantstay/arriere-clients/dist/arriereBackoffice'

import {
  ManualBookingExistingGuestsProps,
  ManualBookingsCandidatesProps,
} from '@new/__global/components/GuestSearchSelect/GuestSearchSelect.types'
// eslint-disable-next-line import/no-cycle
import {
  CreateBookingForExistingGuestProps,
  CreateBookingForNewGuestProps,
  CreateHotelBookingForExistingGuestProps,
  CreateHotelBookingForNewGuestProps,
} from '@new/domains/bookingsTapeChart/components/CreateBookingForm/CreateBookingForm.types'

export default {
  async getManualBookingExistingGuests({ phone, email }: ManualBookingExistingGuestsProps) {
    return arriereBackoffice.queries.manualBookingExistingGuests
      .raw({
        __alias: 'getManualBookingExistingGuests',
        __args: { phone, email },
        total: true,
        results: {
          guestId: true,
          userId: true,
          kind: {
            id: true,
            type: true,
            subType: true,
          },
          name: true,
          emails: true,
          phones: true,
          stays: {
            total: true,
          },
        },
      })
      .then(({ data, errors }) => {
        if (errors) {
          throw Error(errors[0])
        }

        return data
      })
  },

  async getManualBookingCandidates({ contactInfoTerm, pagination }: ManualBookingsCandidatesProps) {
    return arriereBackoffice.queries.manualBookingCandidates
      .raw({
        __alias: 'getManualBookingCandidates',
        __args: { contactInfoTerm, pagination },
        total: true,
        paginationParams: { page: true },
        results: {
          guestId: true,
          userId: true,
          kind: {
            id: true,
            type: true,
            subType: true,
          },
          name: true,
          emails: true,
          phones: true,
          stays: {
            total: true,
          },
        },
      })
      .then(({ data, errors }) => {
        if (errors) {
          throw Error(errors[0])
        }

        return data
      })
  },

  // Mutations

  async createBookingForExistingGuest({
    bookingRequestInfo,
    guestId,
  }: CreateBookingForExistingGuestProps) {
    return arriereBackoffice.mutations.manualBooking.raw({
      __alias: 'createBookingForExistingGuest',
      createForExistingGuest: {
        __args: { request: bookingRequestInfo, guestId },
        id: true,
        hash: true,
      },
    })
  },

  async createBookingForNewGuest({ bookingRequestInfo, guestData }: CreateBookingForNewGuestProps) {
    return arriereBackoffice.mutations.manualBooking.raw({
      __alias: 'createBookingForNewGuest',
      createForNewGuest: {
        __args: { request: bookingRequestInfo, guestData },
        hash: true,
        id: true,
        guestId: true,
      },
    })
  },
}

export const getHotelRoomTypeList = (hotelId: string) =>
  arriereBackoffice.queries.hotelRoomType
    .raw({
      __alias: 'getHotelRoomTypeList',
      list: {
        __args: { hotelId },
        hotelRoomTypeId: true,
        name: true,
        title: true,
      },
    })
    .then(({ data, errors }) => {
      if (errors && errors.length > 0) {
        throw new Error(errors[0]?.message)
      }

      return data.list
    })

export const getRoomTypeUnitsList = (hotelRoomTypeId: string) =>
  arriereBackoffice.queries.hotelRoomType
    .raw({
      __alias: 'getHotelRoomTypeList',
      get: {
        __args: { hotelRoomTypeId },
        hotelRoomTypeId: true,
        units: {
          id: true,
          name: true,
          unitNumber: true,
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors && errors.length > 0) {
        throw new Error(errors[0]?.message)
      }

      return data.get
    })

export const createHotelBookingForExistingGuest = ({
  bookingRequestInfo,
  guestId,
}: CreateHotelBookingForExistingGuestProps) => {
  const hotelRoomTypeId = bookingRequestInfo.hotelRoomTypeId

  if (!hotelRoomTypeId) {
    return Promise.reject(
      new Error('hotelRoomTypeId is required for creating a hotel booking for an existing guest.'),
    )
  }

  return arriereBackoffice.mutations.manualBooking.raw({
    __alias: 'createHotelBookingForExistingGuest',
    createForHotelAndExistingGuest: {
      __args: { request: { ...bookingRequestInfo, hotelRoomTypeId }, guestId },
      id: true,
      hash: true,
    },
  })
}

export const createHotelBookingForNewGuest = ({
  bookingRequestInfo,
  guestData,
}: CreateHotelBookingForNewGuestProps) => {
  const hotelRoomTypeId = bookingRequestInfo.hotelRoomTypeId
  if (!hotelRoomTypeId) {
    return Promise.reject(
      new Error('hotelRoomTypeId is required for creating a hotel booking for an new guest.'),
    )
  }

  return arriereBackoffice.mutations.manualBooking.raw({
    __alias: 'createHotelBookingForNewGuest',
    createForHotelAndNewGuest: {
      __args: { request: { ...bookingRequestInfo, hotelRoomTypeId }, guestData },
      hash: true,
      id: true,
      guestId: true,
    },
  })
}
