import React from 'react'

import {
  AssetWithDocumentProps,
  FilePreviewType,
  FileResolutionProps,
  FileViewMode,
  FileWithError,
} from './UploadArea.types'

interface GetFilesWithErrorsIndexesProps {
  files: AssetWithDocumentProps[]
  filesWithError: FileWithError
}

export const getFilesWithErrorsIndexes = ({
  files,
  filesWithError,
}: GetFilesWithErrorsIndexesProps) => {
  const filesWithErrorsIndexes = files.reduce(
    (fileIndexes: string[], currentFile: AssetWithDocumentProps, index: number) => {
      const fileHasError = Object.keys(filesWithError).includes(currentFile.id)
      if (fileHasError) {
        return [...fileIndexes, `#${index}`]
      }

      return fileIndexes
    },
    [],
  )

  return filesWithErrorsIndexes
}

export const getFileSizeByType = (
  filePreviewType?: FilePreviewType,
  fileViewMode?: FileViewMode,
) => {
  if (filePreviewType === FilePreviewType.photoGallery) {
    return fileViewMode === FileViewMode.list
      ? {
          width: 212,
          height: 212,
        }
      : {
          width: 168,
          height: 188,
        }
  }

  return {
    width: 104,
    height: 104,
  }
}

export const getPreviewTitleByType = (
  filePreviewType?: FilePreviewType,
  files?: AssetWithDocumentProps[],
) => {
  if (filePreviewType === FilePreviewType.photoGallery) {
    const disabledLength = files?.filter(file => file.disabled)?.length
    const enabledLength = files?.filter(file => !file.disabled)?.length

    return (
      <>
        Photo Gallery &bull; {files?.length} photos{' '}
        {disabledLength ? <>&bull; {enabledLength} selected</> : null}
        {disabledLength ? <>&bull; {disabledLength} disabled</> : null}
      </>
    )
  }

  return <>Files &bull; {files?.length} </>
}

export const getImageDimensions = async (imageUrl: string): Promise<FileResolutionProps> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve({ width: img.width, height: img.height })
    img.onerror = reject
    img.src = imageUrl
  })
}
