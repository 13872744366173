import humanizeString from 'humanize-string'
import React, { FC, Fragment } from 'react'

import { ReviewCategory } from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { DotRatings, RatingBar } from '../../../components'
import { ReviewProps as ReviewType } from '../../../components/ReviewCard/ReviewCard.types'
import { directLeadSources } from '../../../constants'
import * as S from './Review.styles'
import { getOverallRating, labelByReviewCategory } from './Review.utils'

interface ReviewProps {
  isLastReview: boolean
  isLastReviewOnPage: boolean
  review: ReviewType
  hideComments?: boolean
}

export const Review: FC<ReviewProps> = ({
  hideComments,
  isLastReview,
  isLastReviewOnPage,
  review,
}) => {
  const { categoryReviews, privateFeedback, source } = review

  const isSourceAvantStay = directLeadSources.includes(source)
  const overallRating = getOverallRating({ categoryReviews })
  const detailedRatings = categoryReviews?.filter(
    reviewDetail =>
      reviewDetail.category !== ReviewCategory.overall &&
      reviewDetail.category !== ReviewCategory.nps,
  )
  const displayReviewComments = !hideComments && (overallRating?.comment || privateFeedback)

  return (
    <S.ReviewCardContainer isLastReview={isLastReview} isLastReviewOnPage={isLastReviewOnPage}>
      <S.ReviewBreakdownContainer>
        <S.OverallContainer>
          <S.ReviewRatingLabel>{isSourceAvantStay ? 'NPS' : 'Overall'}</S.ReviewRatingLabel>
          {overallRating ? (
            <RatingBar
              isNPS={overallRating.category === ReviewCategory.nps}
              isOverall
              rating={overallRating.rating}
            />
          ) : null}
        </S.OverallContainer>
        {detailedRatings?.map(reviewDetail => (
          <Fragment key={`detailedRatings-${review.id}-${reviewDetail.category}`}>
            <DotRatings
              category={
                labelByReviewCategory[reviewDetail.category] ||
                humanizeString(reviewDetail.category)
              }
              rating={reviewDetail.rating}
              shouldHideProgressBar
            />
          </Fragment>
        ))}
      </S.ReviewBreakdownContainer>
      {displayReviewComments && (
        <S.ReviewCommentContainer>
          {overallRating?.comment && (
            <div>
              <S.ReviewCommentHeader>
                {isSourceAvantStay ? 'Private NPS Review' : 'Public Review'}
              </S.ReviewCommentHeader>
              <S.ReviewComment>{overallRating?.comment}</S.ReviewComment>
            </div>
          )}
          {privateFeedback && (
            <div>
              <S.ReviewCommentHeader>Private Review</S.ReviewCommentHeader>
              <S.ReviewComment>{privateFeedback}</S.ReviewComment>
            </div>
          )}
        </S.ReviewCommentContainer>
      )}
    </S.ReviewCardContainer>
  )
}
