import arriereBackoffice from '@avantstay/arriere-clients/dist/arriereBackoffice'

import type { OmniSearchParams } from '@arriere/types/OmniSearch.types'

export const getOmniSearchHomes = ({ term }: OmniSearchParams) => {
  return arriereBackoffice.queries.omniSearchHomes
    .raw({
      __alias: 'getOmniSearchHomes',
      __args: { term },
      total: true,
      results: {
        id: true,
        name: true,
        region: {
          name: true,
        },
        thumbnail: {
          url: true,
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors?.[errors?.length - 1]?.message)
      }

      return data
    })
}

export const getOmniSearchBookings = ({ endDate, startDate, term }: OmniSearchParams) => {
  return arriereBackoffice.queries.bookingOmniSearch
    .raw({
      __alias: 'getOmniSearchBookings',
      __args: { term, from: startDate, to: endDate },
      total: true,
      pages: true,
      results: {
        hash: true,
        guestName: true,
        checkInTiming: {
          effectiveTime: true,
        },
        checkOutTiming: {
          effectiveTime: true,
        },
        folio: {
          summary: {
            total: true,
            balance: true,
            isPaid: true,
            paid: {
              total: true,
              credits: true,
            },
          },
        },
        home: {
          id: true,
          name: true,
          region: {
            name: true,
          },
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors?.[errors?.length - 1]?.message)
      }

      return data
    })
}

export const getOmniSearchGuests = ({ term }: OmniSearchParams) => {
  return arriereBackoffice.queries.guestsOmniSearch
    .raw({
      __alias: 'getOmniSearchGuests',
      __args: { terms: term, pagination: { size: 20, page: 1 } },
      total: true,
      results: {
        id: true,
        userId: true,
        fullName: true,
        email: true,
        ongoing: {
          hash: true,
          checkInTiming: {
            effectiveTime: true,
          },
          checkOutTiming: {
            effectiveTime: true,
          },
          home: {
            name: true,
          },
        },
        next: {
          hash: true,
          checkInTiming: {
            effectiveTime: true,
          },
          checkOutTiming: {
            effectiveTime: true,
          },
          home: {
            name: true,
          },
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors?.[errors?.length - 1]?.message)
      }

      return data
    })
}
